import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GatsbyImgae from '../GatsbyImage';
import moment from 'moment'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  PickupItem,
  PickupDetails,
  PickupProgress,
  SuccessRate,
  ProgressBar,
  PickupIcon,
  PickupAbout,
} from './index.style';

const PickupSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  row,
  col,
  pickupTitle,
  pickupDescription,
  pickupSuccessRate,
  successRateText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PICKUP {
          title
          url
          image          
          date
          at
          views
        }
      }
    }
  `); 
  const formatter = new Intl.NumberFormat('ja-JP');

  return (
    <Box {...sectionWrapper} as="section" id="pickup_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Pickup" />
        </Box>

        <Box {...row}>
          {Data.portfolioJson.PICKUP.map((item, index) => (
            <Box {...col} key={`pickup-item-${index}`}>
              <OutboundLink href={item.url} target="_blank" rel="noopener noreferrer">
              <PickupItem>
                <GatsbyImgae
                      filename={item.image}
                      alt={item.title}
                      style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
                    />
                <PickupDetails>
                    <Heading content={item.title} {...pickupTitle} />             
                </PickupDetails>
                <PickupProgress>
                    <Text content={item.at} {...pickupDescription} />                             
                    <Text content={`${item.views ? formatter.format(item.views)+' views ' : ''} on ${moment(item.date).format('MMM D, YYYY')}`} {...pickupDescription} />                             
                </PickupProgress>
              </PickupItem>
              </OutboundLink>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

PickupSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  pickupTitle: PropTypes.object,
  pickupDescription: PropTypes.object,
  pickupSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

PickupSection.defaultProps = {
  sectionWrapper: {
    pt: '40px',
    pb: '40px',
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: '40px'
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1/2],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  pickupTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '12px',
  },
  pickupDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  pickupSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
};

export default PickupSection;
